import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api";

class SupportStore {

    private rootStore: RootStore;

    private _data = {
        source: "",
        buyDateStart: "",
        buyDateEnd: "",
        brand: "",
        model: "",
        type: "",
        question: "",
        contact: "",
        comment: "",
        attaches: ""
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    get data() {
        return this._data;
    }

    setDataField(fieldname: string, value: any) {
        // @ts-ignore
        this._data[fieldname] = value;
    }

    async send() {
        try {
            const res = await Api.support(this.data)
            console.log(res);
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response.data.message || ex.message);
        }
    }

}

export default SupportStore;