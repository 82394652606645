import { instance } from "./service";

class Api {

    init(tgInitData: any) {
        return instance.post("/validate-init", tgInitData);
    }

    test() {
        return instance.get("/test");
    }

    support(data: any) {
        return instance.post("/support", data);
    }

}

export default new Api();
