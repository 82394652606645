import log from 'loglevel';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { UseStore } from "@hooks/use-store";
import { RootStore } from "@store/root-store";

import App from '@components/app/app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//log.setLevel("debug");
log.enableAll();

root.render(
    <React.StrictMode>
        <UseStore.Provider value={ new RootStore() }>
            <App />
        </UseStore.Provider>
    </React.StrictMode>
);
