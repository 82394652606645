import React from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Index from "../views/index/index";

const AppRoutes = (props: any) => {
    return (
        <Routes>
            <Route path='*' element={<Index/>} />
        </Routes>
    );
}

export default observer(AppRoutes);