import cn from "classnames";
import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { useStore } from "@hooks/use-store";
import styles from "./support.module.scss";
import SupportStore from "@store/support-store";


const Support = () => {

    const [ isMarket, setIsMarket ] = useState(false);
    const { supportStore } = useStore();

    useEffect(() => {
    }, [ ]);

    const onChange = (el: any) => {
        console.log(el.target.name, el.target.value);
        if (el.target.name === 'source') {
            setIsMarket(el.target.value === 'market');
        }
        if (el.target.name === 'market') {
            supportStore.setDataField("source", el.target.value);
        } else {
            supportStore.setDataField(el.target.name, el.target.value);
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        console.log(toJS(supportStore.data));
        await supportStore.send();
    }

    return (
        <div>
            <div className="d-flex gap-2">
                <a href="/main">
                    <svg className={styles.back} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-left</title><path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" /></svg>
                </a>
                <h1>Техподдержка по гарантии</h1>
            </div>
            <form onSubmit={onSubmit}>
                <ol>
                    <li className="mb-2">
                        Товар куплен на сайте LIDER-3D или на маркептлейсе?
                        <div className="form-check mt-2">
                            <input className="form-check-input" type="radio" name="source" id="source1" value="site" onChange={onChange} required />
                            <label className="form-check-label" htmlFor="source1">
                                на сайте LIDER-3D
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="source" id="source2" value="market" onChange={onChange} required />
                            <label className="form-check-label" htmlFor="source2">
                                на маркептлейсе
                            </label>
                        </div>
                        {isMarket &&
                        <select className="form-select mt-3" name="market" onChange={onChange} value={supportStore.data.source} required>
                            <option value="">-- Выберите маркетплейс --</option>
                            <option value="Wildberries">Wildberries</option>
                            <option value="Яндекс">Яндекс</option>
                            <option value="Ozon">Ozon</option>
                            <option value="СберМегаМаркет">СберМегаМаркет</option>
                        </select>
                        }
                    </li>
                    <li className="mb-2">
                        Укажите дату покупки/диапазон дат
                        <div className="row my-2">
                            <div className="col-6 pe-2">
                                <input className="form-control" type="date" name="buyDateStart" onChange={onChange} required />
                            </div>
                            <div className="col-6 ps-2">
                                <input className="form-control" type="date" name="buyDateEnd" onChange={onChange} />
                            </div>
                        </div>
                    </li>
                    <li className="mb-2">
                        Укажите купленный товар
                        <div className="my-2">
                            <input className="form-control mb-2" type="text" name="brand" placeholder="Бренд" onChange={onChange} required />
                            <input className="form-control mb-2" type="text" name="model" placeholder="Модель" onChange={onChange} required />
                            <input className="form-control mb-2" type="text" name="type" placeholder="Тип" onChange={onChange} required />
                        </div>
                    </li>
                    <li className="mb-2">
                        Укажите запрос/проблему или технический запрос
                        <div className="my-2">
                            <textarea className="form-control mb-2" rows={5} name="question" onChange={onChange} placeholder="Введите текст" required />
                        </div>
                    </li>
                    <li className="mb-2">
                        Как с вами можно связаться
                        <div className="my-2">
                            <input className="form-control mb-2" type="text" name="contact" onChange={onChange} placeholder="телеграмм, почта" required />
                        </div>
                    </li>
                    <li className="mb-2">
                        Свободный комментарий
                        <div className="my-2">
                            <textarea className="form-control mb-2" rows={5} name="comment" onChange={onChange} placeholder="Введите текст" required />
                        </div>
                    </li>
                    <li className="mb-2">
                        Укажите ссылки фото или видео
                        <div className="my-2">
                            <textarea className="form-control mb-2" rows={5} name="attaches" onChange={onChange} placeholder="Введите текст" required />
                        </div>
                    </li>
                </ol>
                <button className="btn btn-primary w-100">Отправить</button>
            </form>
        </div>
    );
};

export default observer(Support);
