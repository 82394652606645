import log from "loglevel";
import mitt from 'mitt';
import { makeObservable, observable, computed, action } from "mobx";
import { toast } from "react-toastify";

import MainStore from "@store/main-store";
import IndexStore from "@store/index-store";
import SupportStore from "@store/support-store";
import Api from "@api/api";

export class RootStore {

    // эмиттер для шины сообщений
    emitter = mitt();

    tg: any;

    _showProgressBar = false;

    get showProgressBar() {
        return this._showProgressBar;
    }
    setShowProgressBar(val: boolean) {
        this._showProgressBar = val;
    }

    rootStore            = this;

    indexStore           = new IndexStore(this);
    mainStore            = new MainStore(this);
    supportStore         = new SupportStore(this);

    get token(): any {
        return localStorage.getItem("token");
    }
    set token(value: string) {
        localStorage.setItem("token", value);
    }

    constructor() {
        makeObservable(this, {
            _showProgressBar: observable,
            showProgressBar: computed,
            setShowProgressBar: action
        });
        this.init().then();
    }

    async init() {
        log.debug("init");
        try {
            this.setShowProgressBar(true);
            // @ts-ignore
            this.tg = window.Telegram.WebApp;
            this.tg.expand();
            const res = await Api.init(this.tg.initData)
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.token = res.data.tokens.access;
            }
            this.setShowProgressBar(false);
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
            this.setShowProgressBar(false);
        }
    }

}
