import cn from "classnames";
import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";

import { useStore } from "@hooks/use-store";
import styles from "./lk.module.scss";


const Lk = () => {

    const { rootStore } = useStore();

    useEffect(() => {
    }, [ ]);

    return (
        <div>
            <div className="d-flex gap-2">
                <a href="/main">
                    <svg className={styles.back} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-left</title><path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" /></svg>
                </a>
                <h1>Личный кабинет</h1>
            </div>
            <div>
                initData:
                {JSON.stringify(rootStore.tg.initData)}
            </div>
        </div>
    );
};

export default observer(Lk);
