import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import NotFound from "@layouts/not-found/NotFound";
import Loading from "@components/loading/Loading";
import Lk from "@views/lk/lk";
import Support from "@views/support/support";
import Claim from "@views/claim/claim";
import Questions from "@/views/questions/questions";

const Main = React.lazy(() => import('@views/main/main'));

const MainRoutes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route index element={<Navigate to="/main" replace />} />
                <Route path={"/main"} element={<Main />} />
                <Route path={"/lk"} element={<Lk />} />
                <Route path={"/support"} element={<Support />} />
                <Route path={"/claim"} element={<Claim />} />
                <Route path={"/questions"} element={<Questions />} />
                <Route path='*' element={<NotFound/>} />
            </Routes>
        </Suspense>
    );
}

export default observer(MainRoutes);