import React, {useEffect} from 'react';
import { observer } from "mobx-react-lite";
import MainRoutes from "@routing/main-routes";

import { useStore } from "@hooks/use-store";
import ProgressBar from "@components/progress-bar/progress-bar";

const Index = () => {

    const { indexStore } = useStore();

    useEffect(() => {
    }, []);

    return (
        <>
            <ProgressBar />
            <div className="container p-3">
                <MainRoutes/>
            </div>
        </>
    );
};

export default observer(Index);
