import log from "loglevel";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api";

class MainStore {

    private rootStore: RootStore;

    get tg() {
        return this.rootStore.tg;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    showMainButton = () => {
        if (this.tg.MainButton.isVisible) {
            this.tg.MainButton.hide();
        } else {
            this.tg.MainButton.show();
        }
    }

    async test() {
        log.debug("test");
        try {
            const res = await Api.test();
            if (res.data.status === 'OK') {
                alert(JSON.stringify(res.data.ip));
            }
        } catch (ex: any) {
            console.log(ex.message);
        }
    }

}

export default MainStore;