import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Bounce } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from "@routing/app-routes";
import './app.css';

function App() {
    return (
        <BrowserRouter>
          <AppRoutes/>
          <ToastContainer
              position="bottom-right"
              autoClose={3000}
              theme="colored"
              closeOnClick
              hideProgressBar={true}
              draggable
              transition={Bounce}
          />
        </BrowserRouter>
    );
}

export default App;
