import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";

class IndexStore {

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default IndexStore;